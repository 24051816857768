import React from "react";
import { graphql } from "gatsby"
import SEO from "../components/seo"


const Contact = ({data}) => {
	const siteTitle = data.site.siteMetadata.title

	return (
		<div className="page-wrapper">
            <SEO 
                title="Contact" 
                description="Contact us at Subflipper if you have any questions"
                pagePath="/contact"
            />

			<h1>Contact</h1>
            <div className="divider-hr">
                <span className="divider-span"></span>
            </div>

			<p>
				If you have any questions you can e-mail us at <a href="mailto:support@subflipper.com">support@subflipper.com</a> and we'll get back to you as soon as possible.
			</p>
		</div>
	)
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`